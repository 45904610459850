/*
      www.OnlineWebFonts.Com
*/
@font-face {
  font-family: "Spotify";
  src: url("./apps/assets/spotify.woff2") format("woff2"), url("./apps/assets/spotify.ttf") format("truetype");
}

body {
  --dark-txt: #000;
  --txt-col: #222;
  --med-txt: #3c3c3c;
  --comp-txt: #ddd;
  --gray-txt: #555;
  --sat-txt: #777;
  --clrPrm: #0067c0;
  --wintheme: #eee;
}

body[data-theme="dark"] {
  --dark-txt: #fff;
  --txt-col: #ddd;
  --med-txt: #c3c3c3;
  --comp-txt: #222;
  --gray-txt: #aaa;
  --sat-txt: #999;
  --clrPrm: #4cc2ff;
  --wintheme: #1e1e26;
}

.edgeBrowser {
  --bg0: #e7eaec;
  --bg1: #f7fafc;
  --ipbar: #fefefe;
  --shd: rgba(80, 80, 80, 0.2);
  background: var(--bg0);
}

body[data-theme="dark"] .edgeBrowser {
  --bg0: #222228;
  --ipbar: #222228;
  --bg1: rgb(45, 45, 51);
  --shd: rgba(0, 0, 0, 0.3);

  .edgenavicon{
    filter: invert(1);
  }
}

.vscodeWn {
  background: rgb(37, 37, 38);
}

.wnstore {
  --bg-color: #f3f3f3;
  --nav-btn-hov: #ffffff;
  --nav-blue: #2570ff;
  --page-bg: #f4f4f4;
  --page-bd-col: #e2e2e2;
  --rib-bg: #ffffffb8;
  --rib2-bg: #fdfdfd;
  --det-bg: #f9f9f9;
  background: var(--bg-color);
}

body[data-theme="dark"] .wnstore {
  --bg-color: #1c2126;
  --nav-btn-hov: #454545;
  --nav-blue: #4cc2ff;
  --page-bg: #24292c;
  --page-bd-col: #292828;
  --rib-bg: #2f2e30b8;
  --rib2-bg: #2c2f32;
  --det-bg: #282c32;
  background: var(--bg-color);
}

.wnterm {
  background: rgb(16, 16, 16);
}

.notepad {
  background: #fefefe;
}

.calcApp {
  --bg1: rgba(243, 243, 243, 0.9);
  --bg2: #ffffff;
  --bg3: #f9f9f9;
  --err-bg: rgb(255, 255, 255, 0.1);
  --err-txt: #999;
  background: var(--bg1);
  color: var(--dark-txt);
  -webkit-backdrop-filter: blur(21px);
  backdrop-filter: blur(21px);
}

body[data-theme="dark"] .calcApp {
  --bg1: rgba(32, 32, 32, 0.9);
  --bg2: rgba(255, 255, 255, 0.12);
  --bg3: rgba(255, 255, 255, 0.08);
  --err-bg: rgba(255, 255, 255, 0.033);
  --err-txt: #777;

  .menuBars {
    filter: invert(1);
  }
}

.msfiles,
.whiteBoard {
  background: var(--comp-txt);
}

.spotify {
  background: rgb(24, 24, 24);
}

.discordWn {
  background: #36393f;
}

.lightWindow {
  background: #e7eaec;
}

.darkWindow {
  background: #1a1614;
}

.wnCam {
  background: #060606;
}
